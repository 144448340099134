/* @import url('https://rsms.me/inter/inter.css'); */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html {
  font-family: 'IBM Plex Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
}

a {
  /* all: unset; */
  color: rgb(230, 0, 121);
  cursor: pointer;
}

.nav-container {
  display: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.scene-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scene-inner {
  cursor: pointer;
  height: min(60vw, 60vh);
  width: 100%;
  /* max-width: 1000px; */
  position: relative;
}

.total {
  .total-number {
    font-size: 7em;
    font-weight: 800;
    font-style: italic;
  }

  .about-cubes {
    font-weight: 300;
  }

  pointer-events: none;
  font-size: 1.5em;
  font-weight: 600;
  width: 50%;
  position: absolute;
  bottom: 1em;
  left: 1em;
  z-index: 1;
  mix-blend-mode: multiply;
  color: rgb(230, 0, 121);
}
.container {
  display: grid;
  padding: 3em 5em 3em 5em;
  grid-template-rows: auto 1fr;
  gap: 2em;
  /* position: absolute; */
  width: 100%;
  min-height: 80vh;
  overflow: hidden;
}

.text-container {
  min-height: initial;
  padding-top: 0;
  width: 50%;
}

.nav {
  display: grid;
  grid-template-columns: auto 0.25fr 1fr 0.25fr auto auto;
  gap: 1em;
}

.label {
  margin: 0;
  font-size: 2.5em;
  font-weight: 400;
  letter-spacing: 0;
}

.label::after {
  content: 'Cancer Research UK';
}

.caption {
  font-size: 1.5em;
  display: inline-block;
  max-width: 500px;
}

.caption::after {
  content: '100 push-ups a day challenge for Cancer Research UK';
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(230, 0, 121);
  color: white;
  height: 52px;
  border-radius: 30px;
  padding: 0em 2em;
  white-space: pre;
}

.button.gray {
  background: #ccc;
  color: #141622;
}

@media (max-width: 1200px) {
  .container {
    padding: 2em 4em 2em 4em;
    gap: 2em;
  }
  .button {
    height: 48px;
    border-radius: 30px;
    padding: 0em 2em;
  }
  .label {
    font-size: 2em;
  }
  .caption,
  .total {
    font-size: 1.5em;
  }

  .text-container {
    padding-top: 0;
  }

  .scene-inner {
    height: 80vh;
  }
}

@media (max-width: 800px) {
  /* .caption::after {
    content: 'OSS dev collective  for the creative space';
  }
  .label::after {
    content: 'PMNDRS';
  } */
  .container {
    padding: 2em 3em 2em 3em;
    gap: 2em;
  }
  .button {
    height: 36px;
    border-radius: 30px;
    padding: 0em 1.25em;
    font-size: 0.7em;
  }
  .label {
    font-size: 1em;
  }
  .caption,
  .total {
    font-size: 1em;
  }
  .text-container {
    padding-top: 0;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .nav {
    gap: 0.5em;
  }
  /* .caption::after {
    content: 'OSS dev collective';
  } */
  .container {
    padding: 1em;
    gap: 1em;
  }
  .text-container {
    padding-top: 0;
    width: 100%;
  }

  .caption::after {
    content: '100 push-ups a day for Cancer Research';
  }
}
